"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function BarChartIcon(props) {
    return (React.createElement("svg", __assign({ width: "20px", height: "16px", viewBox: "0 0 20 16" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" }))),
        React.createElement("g", { transform: "translate(-1140 -433)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v547H0z" }),
            React.createElement("rect", { stroke: "#DCE7F1", fillOpacity: 0.35, fill: "#FFF", x: 1020.5, y: 416.5, width: 263, height: 50, rx: 8 }),
            React.createElement("path", { d: "M1142 444v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1zm7 1h2a1 1 0 001-1v-10a1 1 0 00-1-1h-2a1 1 0 00-1 1v10a1 1 0 001 1zm6 0h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2a1 1 0 00-1 1v6a1 1 0 001 1zm4.5 2h-19a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z", fill: "#3498DB", fillRule: "nonzero" }))));
}
var MemoBarChartIcon = React.memo(BarChartIcon);
exports["default"] = MemoBarChartIcon;
