"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
exports.useModal = void 0;
var react_1 = require("react");
exports.useModal = function () {
    var _a = __read(react_1.useState(false), 2), modalOpen = _a[0], setModalOpen = _a[1];
    var toggle = function () { return setModalOpen(!modalOpen); };
    return [modalOpen, setModalOpen, toggle];
};
