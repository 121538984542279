import styled, { ThemeProvider } from 'styled-components';
import defaultTheme from '@tooleybook/ui-lib/lib/src/themes/DefaultTheme';
import {EmptyBooks} from "@tooleybook/ui-lib";

import AppHeader, { AppContextType } from '@tooleybook/ui-lib/lib/src/components/appheader';
import AppInitializer from '@tooleybook/ui-lib/lib/src/core/AppInitializer';

import { StyledAppShell } from '@tooleybook/ui-lib/lib/src/elements/StyledAppShell';
import { StyledContent } from '@tooleybook/ui-lib/lib/src/elements/StyledContent';
import NotFound from '@tooleybook/ui-lib/lib/src/components/errorpages/NotFound';

import RouteHandler from '@tooleybook/ui-lib/lib/src/components/security/RouteHandler';


// Imported containers
import LoginSuccess from '@tooleybook/ui-lib/lib/src/components/security/LoginSuccess';
import BookDetailContainer from '@tooleybook/ui-lib/lib/src/containers/BookDetailContainer';
import BookFormEditorContainer from '@tooleybook/ui-lib/lib/src/containers/BookFormEditorContainer';
import ChapterFormEditorContainer from '@tooleybook/ui-lib/lib/src/containers/ChapterFormEditorContainer';
import SceneFormEditorContainer from '@tooleybook/ui-lib/lib/src/containers/SceneFormEditorContainer';
import MyBooksContainer from '@tooleybook/ui-lib/lib/src/containers/MyBooksContainer';
import SceneEditorContainer from '@tooleybook/ui-lib/lib/src/containers/SceneEditorContainer';
import BetaSwitchContainer from '@tooleybook/ui-lib/lib/src/containers/BetaSwitchContainer';
import LoginE2EContainer from '@tooleybook/ui-lib/lib/src/containers/LoginE2EContainer';
import ExportFormBookContainer from '@tooleybook/ui-lib/lib/src/containers/ExportFormBookContainer';
import SearchResultContainer from '@tooleybook/ui-lib/lib/src/containers/SearchResultContainer';
import ErrorContainer from '@tooleybook/ui-lib/lib/src/containers/ErrorContainer';

// import StateViewer, { StyledStateViewer } from '@tooleybook/ui-lib/lib/src/components/devtools/StateViewer';
import StateViewer2 from '@tooleybook/ui-lib/lib/src/components/devtools/StateViewer2';
import Version from '@tooleybook/ui-lib/lib/src/components/version';


// currently storing like api url
// i hate the .env eco so not sure of better way atm
// should never have sensitive info in react app so :shrug
import Settings from './core/Settings';

// Local containers

// Logging
import log from 'loglevel';
import { isLocalHost } from '@tooleybook/ui-lib/lib/src/utils/core';
const logLevel = isLocalHost() === true ? 'TRACE' : 'SILENT';
log.setLevel(logLevel); // TRACE, DEBUG, INFO, WARN, ERROR

// Store
import { RecoilRoot } from 'recoil';
import { initializeState } from '@tooleybook/ui-lib/lib/src/core/LocalStore';

// Data
// import * as dataProvider from '@tooleybook/ui-lib/lib/src/dataprovider/MockDataProvider';
import * as dataProvider from './dataprovider/SQLDataProvider';

// Components
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from 'react-toasts';

// const StyledAppShell = styled.div`
//   font-family: ${props => props.theme.fontFamily.default};
//   background-color: ${props => props.theme.colors.lightBackground};
//   justify-content: center;
//   text-align: center;
//   align-items: center;
//   position: absolute;

//   top: 0;
//   left: 0;
//   width: 100%;
//   color: ${props => props.theme.colors.text};

//   /* Note: using height:100% causes scrollTo to stop working!!! */
//   /* height: 100%; */
//   // overflow-x: hidden;
//   min-height: 100%;
// `;

// Routing
import { useRoutes, usePath } from 'hookrouter';

const  routes = {
  '/': () => <EmptyBooks />,
  '/cylogin/:accessToken': ({ accessToken }) => <LoginE2EContainer accessToken={accessToken} dataProvider={dataProvider} />,
  '/loginsuccess': () => <LoginSuccess dataProvider={dataProvider} />,
  '/emptybooks': () => <EmptyBooks />,
  '/bookdetail/:id': ({ id }) => (
    <BookDetailContainer bookId={Number(id)} dataProvider={dataProvider} />
  ),
  '/editbook/:id': ({ id }) => (
    <BookFormEditorContainer dataProvider={dataProvider} bookId={Number(id)} />
  ),
  '/createbook': () => (
    <BookFormEditorContainer dataProvider={dataProvider} bookId={-1} />
  ),
  '/createchapter': () => (
    <ChapterFormEditorContainer dataProvider={dataProvider} />
  ),
  '/editchapter/:id': ({ id }) => (
    <ChapterFormEditorContainer
      dataProvider={dataProvider}
      chapterId={Number(id)}
    />
  ),
  '/editscene/:id': ({ id }) => (
    <SceneFormEditorContainer
      sceneId={Number(id)}
      dataProvider={dataProvider}
    />
  ),
  '/createscene': () => (
    <SceneFormEditorContainer sceneId={-1} dataProvider={dataProvider} />
  ),
  '/mybooks': () => <MyBooksContainer dataProvider={dataProvider} />,
  '/export/:section': ({section}) => <ExportFormBookContainer section={section} dataProvider={dataProvider} />,
  '/sceneeditor/:chapterId/:sceneId': ({ chapterId, sceneId }) => (
    <SceneEditorContainer
      dataProvider={dataProvider}
      chapterId={Number(chapterId)}
      sceneId={Number(sceneId)}
      baseApiUrl={Settings.API_ROOT}
      assetRoot={Settings.ASSET_ROOT}
    />
  ),
  '/trybeta': () => <BetaSwitchContainer dataProvider={dataProvider} />,
  // '/stateviewer': () => <StateViewer  hideHeader={true} inline={true} dataProvider={dataProvider} />,
  '/test': () => <StateViewer2 dataProvider={dataProvider} />,
  '/search': () => <SearchResultContainer  dataProvider={dataProvider} />,
  '/comingsoon/:message?': ({message}) => <>{message !== 'undefined' && <strong>{decodeURIComponent(message)}</strong>} <br />coming soon...  <br /><br /><a onClick={() => window.history.back()} style={{color:'blue', cursor: 'pointer'}}>back</a></>,
  '/error': () => <ErrorContainer>snaps!</ErrorContainer>,

};
interface AppShellProps {
  children?: any;
}

function AppShell(props: AppShellProps) {

  // @ts-ignore
  const routeResult = useRoutes(routes);
  const path = usePath();
  const context =
    path === '/emptybooks'
      ? AppContextType.EMPTY_BOOKS
      : AppContextType.BOOK_DETAIL;

  return (
    <>
      <ThemeProvider theme={defaultTheme}>

        <RecoilRoot initializeState={initializeState}>

          {
            // @ts-ignore
            <RouteHandler path={path} />
          }

          <ToastsContainer
            store={ToastsStore}
            lightBackground
            position={ToastsContainerPosition.BOTTOM_RIGHT}
          />

          {!props.children && (
            <StyledAppShell>
              <AppInitializer dataProvider={dataProvider}>
                { routeResult?.props?.hideHeader !== true &&
                  <AppHeader context={context} dataProvider={dataProvider} />
                }
                <StyledContent>{routeResult || <NotFound />}</StyledContent>
              </AppInitializer>
            </StyledAppShell>
          )}

          {/* Provide own StyledAppShell (useful like showing story dialog or 
						if I need to hijack the whole screen) 
				  */}
          {props.children && props.children}

          <Version />

        </RecoilRoot>

      </ThemeProvider>
    </>

  )
}

export default AppShell;