"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function PencilIcon(props) {
    return (React.createElement("svg", __assign({ width: "14px", height: "15px", viewBox: "0 0 14 15" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" }))),
        React.createElement("g", { transform: "translate(-1036 -434)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v547H0z" }),
            React.createElement("rect", { stroke: "#DCE7F1", fill: "#FFF", x: 1020.5, y: 416.5, width: 263, height: 50, rx: 8 }),
            React.createElement("path", { d: "M1046.748 439.822a.437.437 0 010 .582l-6.254 6.728c-.15.162-.33.29-.528.374l-3.368 1.464a.37.37 0 01-.419-.092.432.432 0 01-.147-.515l1.335-3.61c.079-.212.197-.404.349-.565l6.262-6.712a.375.375 0 01.276-.123c.103 0 .202.045.275.123l2.22 2.346zm2.91-4.907l-.512-.549a1.126 1.126 0 00-.822-.366h-.59a1.125 1.125 0 00-.838.366l-1.405 1.522a.437.437 0 000 .582l2.18 2.37a.375.375 0 00.276.123.375.375 0 00.276-.123l1.435-1.513c.223-.238.346-.562.342-.898v-.633c0-.33-.123-.647-.342-.881z", fill: props.color || '#3498DB', fillRule: "nonzero" }))));
}
var MemoPencilIcon = React.memo(PencilIcon);
exports["default"] = MemoPencilIcon;
