"use strict";
exports.__esModule = true;
exports.ChapterSchema = void 0;
var yup_1 = require("yup");
exports.ChapterSchema = yup_1.object({
    chapterId: yup_1.number().required()["default"](-1),
    projectId: yup_1.number(),
    dateCreated: yup_1.string().required()["default"](new Date().toISOString()),
    dateUpdated: yup_1.string().required()["default"](new Date().toISOString()),
    purpose: yup_1.string().nullable()["default"](''),
    sortOrder: yup_1.number().required(),
    title: yup_1.string().required()["default"]('')
});
