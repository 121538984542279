"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function MenuCaretDown(props) {
    return (React.createElement("svg", __assign({ width: 12, height: 7 }, props),
        React.createElement("defs", null,
            React.createElement("filter", { x: "-7.2%", y: "-1.7%", width: "114.4%", height: "104%", filterUnits: "objectBoundingBox", id: "prefix__a" },
                React.createElement("feOffset", { dy: 4, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.15 0", "in": "shadowBlurOuter1" })),
            React.createElement("path", { id: "prefix__b", d: "M1080 80h360v1286h-360z" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("g", { transform: "translate(-1404 -703)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__a)", xlinkHref: "#prefix__b" }),
                React.createElement("use", { fill: "#FCFDFE", xlinkHref: "#prefix__b" })),
            React.createElement("path", { d: "M6.72 6.78a.75.75 0 01-.53.22h-.38a.77.77 0 01-.53-.22L.15 1.64a.5.5 0 010-.71L.86.22a.49.49 0 01.7 0L6 4.67 10.44.22a.5.5 0 01.71 0l.7.71a.5.5 0 010 .71L6.72 6.78z", fill: "#8A9AA5", fillOpacity: 0.5, fillRule: "nonzero" }))));
}
var MemoMenuCaretDown = React.memo(MenuCaretDown);
exports["default"] = MemoMenuCaretDown;
