const local =
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'dev.tooleybook.com';

const apiRoot = local ? 'http://localhost:3050/api' : 'https://tooleybook.com/api';

// Assets are stored under v1 for backward compatibility until v1 is obsolved
const assetRoot = local ? 'http://localhost:3333/assets' : 'https://tooleybook.com/app/assets';

const Settings = {
  API_ROOT: apiRoot,
  ASSET_ROOT: assetRoot
};

export default Settings;
