// Need to use local store
// 1.) Load all data into local store on login
// 2.) Now use localstore to get/udpate!

// Local Tarzan (to get data from)
// http://localhost:3050/api/scenes/sceneinfo/project/27821
// http://localhost:3050/api/projects
// http://localhost:3050/api/projects/

// Demo will load from file on login
// import booksFromDisk from './TarzanAndTheGoldenLion/projects.json';
// import chaptersFromDisk from './TarzanAndTheGoldenLion/chapters.json';
// import scenesFromDisk from './TarzanAndTheGoldenLion/scenes.json';
// import listsFromDisk from './TarzanAndTheGoldenLion/lists.json';
// import listitemsFromDisk from './TarzanAndTheGoldenLion/listitems.json';




// EASY REF
// const res = await axios.get(`${baseApi}/chapters/project/${bookId}`);
// return res.data;




import axios from 'axios';
import Settings from '../core/Settings';
import { getStoreValue, setStoreValue } from '@tooleybook/ui-lib/lib/src/core/LocalStore';
import { AuthenticatedUser } from '@tooleybook/ui-lib/lib/src/contract/types/user/AuthenticatedUser';
// import { ChapterSchema } from '@tooleybook/ui-lib/lib/src/contract/types/chapter/types/Chapter';
import { SortOrder } from '@tooleybook/ui-lib/lib/src/contract/types/nav/SortOrder';
import log from 'loglevel';
import { SceneSchema, Scene } from '@tooleybook/ui-lib/lib/src/contract/types/scene/Scene';

import Cookies from 'js-cookie';
import { isLocalHost } from '@tooleybook/ui-lib/lib/src/utils/core';


axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // @ts-ignore
      if (!window.Cypress) {
        document.location.replace('https://tooleybook.com');
      } else {
        document.location.replace('http://localhost:2000/cylogin');
      }
      return false;
    }
    console.log('api call error', error);
    // Dont return error, handled at actions
    // return error;
  },
);

const baseApi = Settings.API_ROOT;

// TODO: simulate delay.
const randomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomDelay = async (min, max) => {
  // return;
  const val = randomNumber(min, max);
  return new Promise(resolve => setTimeout(() => resolve(val), val));
};

// TODO : MOVE!
function tryParse(obj) {
  try {
    return JSON.parse(obj);
  } catch (e) {
    return null;
  }
}

export const loginE2E = async access_token => {
  log.debug('provider:loginE2E', access_token);

  const res = await axios.post(`${baseApi}/auth/e2e`, { access_token });

  const { firstName, lastName, activeProjectId, email } = res.data;

  console.log('LOGIN E2E>>>>>>>>', res.data);

  const user: AuthenticatedUser = {
    activeBookId: activeProjectId,
    email,
    firstName,
    imageUrl: '',
    isAuthenticated: true,
    lastName,
    uid: ''
  };

  setStoreValue('user', user);
  return user;
};

export const loginUser = async (cookie) => {
  log.debug('provider:loginUser', cookie);

  const userObj = tryParse(cookie);
  if (userObj.token) {
    const { firstName, lastName, authenticated, activeProjectId, imageUrl, email, uid } = userObj;
    const user: AuthenticatedUser = {
      activeBookId: activeProjectId,
      email,
      firstName,
      imageUrl,
      isAuthenticated: authenticated,
      lastName,
      uid
    };

    setStoreValue('user', user);
    return user;
  }
}

export const logout = async () => {
  log.debug('provider:loginUser', logout);

  await axios.post(`${baseApi}/auth/logout`);

  // Remove stored user
  localStorage.clear();

  const path = isLocalHost()
    ? undefined
    : { domain: 'tooleybook.com', path: '/' };
  Cookies.remove('x-user', path);
  Cookies.remove('x-auth-token', path); 

  return {
    isAuthenticated: false,
    firstName: null,
    lastName: null,
  };
};

export const loginDemo = async () => {
  const user = {
    isAuthenticated: true,
    activeBookId: 27821,
    firstName: 'Demo',
    lastName: 'User',
  };

  // setStoreValue('books', booksFromDisk);
  // setStoreValue('chapters', chaptersFromDisk);
  // setStoreValue('scenes', scenesFromDisk);
  // setStoreValue('lists', listsFromDisk);
  // setStoreValue('listitems', listitemsFromDisk);
  setStoreValue('user', user);

  return user;
};

export const logoutDemo = async () => {
  console.log('logging out...');
  localStorage.clear();
  return {
    isAuthenticated: false,
    firstName: null,
    lastName: null,
  };
};

export const getBook = async (bookId: number, updateDefault) => {
  log.debug('provider:getBook', bookId);

  const res = await axios.get(`${baseApi}/projects/project/${bookId}/v2`);
  const book = res.data;

  if (updateDefault !== false) {
    // Update users activeProjectId
    const user = getStoreValue('user');
    setStoreValue('user', { ...user, activeBookId: bookId });
  }

  return book;
};

export const getBooks = async (): Promise<any[]> => {
  log.debug('provider:getBooks');

  const bookResponse = await axios.get(`${baseApi}/projects/v2`);
  return bookResponse.data;
};

export const getAllTopLevelNavigationByProject = async bookId => {
  log.debug('provider:getAllTopLevelNavigationByProject ', bookId);

  const res = await axios.get(`${baseApi}/projects/toplevelnav/${bookId}`);
  return res.data;
};

export const getAllListsByProject = async projectId => {
  log.debug('provider:getAllListsByProject ', projectId);

  const res = await axios.get(`${baseApi}/lists/project/${projectId}`);
  return res.data;
};

//
export const getTimelinePreview = async bookId => {
  log.debug('provider:getTimelinePreview', bookId);

  const res = await axios.get(`${baseApi}/scenes/sceneinfo/project/${bookId}`);
  return res.data;
};

export const getChaptersByBookId = async bookId => {
  log.debug('provider:getChaptersByBookId ', bookId);

  const res = await axios.get(`${baseApi}/chapters/project/${bookId}`);
  return res.data;
};

export const getChapterById = async chapterId => {
  log.debug('provider:getChapterById', chapterId);

  const res = await axios.get(`${baseApi}/chapters/${chapterId}`);
  return res.data;
};

export const getScenesByChapterId = async chapterId => {
  log.debug('provider:getScenesByChapterId', chapterId);

  const res = await axios.get(`${baseApi}/scenes/chapter/${chapterId}`);
  return res.data;
};

export const getSceneById = async sceneId => {
  log.debug('provider:getSceneById', sceneId);

  const res = await axios.get(`${baseApi}/scenes/${sceneId}`);
  return res.data;
};

export const saveBook = async book => {
  log.debug('provider:saveBook', book);

  // Save book
  const res = await axios.post(`${baseApi}/projects`, book);
  const projectId = res.data.projectId;

  // Save cover
  if(book.bookCoverData) {
    log.debug('provider:uploading cover art');

    let formData = new FormData();
    formData.append('images', book.bookCoverData);

    await axios.post(`${baseApi}/projects/images/bookcover/${projectId}`, formData);
  }

  return {...book, projectId }
};

export const saveChapter = async chapter => {
  log.debug('provider:saving chapter', chapter);

  const res = await axios.post(`${baseApi}/chapters`, chapter);
  return {...chapter, chapterId: res.data.chapterId};
};

export const saveChapterSortOrder = async (newSortOrder: SortOrder[]) => {
  log.debug('provider:saveChapterSortOrder', newSortOrder);

  // map ids proper
  const mapped = newSortOrder.map(a => ({ chapterId: a.id, sortOrder: a.sortOrder }));
  const res = await axios.post(`${baseApi}/chapters/sort`, mapped);
  return res.data;
};

export const saveSceneSortOrder = async (newSortOrder: SortOrder[]) => {
  log.debug('provider:saveSceneSortOrder', newSortOrder);

  // map ids proper
  const mapped = newSortOrder.map(a => ({ sceneId: a.id, sortOrder: a.sortOrder }));
  const res = await axios.post(`${baseApi}/scenes/sort`, mapped);
  return res.data;
};

export const saveListItemSortOrder = async (newSortOrder: SortOrder[]) => {
  log.debug('provider:saveListItemSortOrder', newSortOrder);

  // map ids proper
  const mapped = newSortOrder.map(a => ({ sceneId: a.id, sortOrder: a.sortOrder }));
  const res = await axios.post(`${baseApi}/lists/sort`, mapped);
  return res.data;
};

export const saveScene = async (scene:Scene) => {
  log.debug('provider:saving scene', scene);

  const res = await axios.post(`${baseApi}/scenes`, scene);
  const sceneId = res.data.sceneId;
  return {...scene, sceneId}
};

export const saveSceneContent = async (sceneId, content) => {
  log.debug('provider:saving scene content', content);
  const res = await axios.post(`${baseApi}/scenes/content/${sceneId}`, content);
  return res.data;
};

export const deleteChapter = async chapterId => {
  log.debug('provider:delete chapter', chapterId);

  const res = await axios.delete(`${baseApi}/chapters/${chapterId}`);
  return res.data;
};

export const deleteScene = async sceneId => {
  log.debug('provider:delete scene', sceneId);
  
  const res = await axios.delete(`${baseApi}/scenes/${sceneId}`);
  return res.data;
};

export const getAllLocationsByBook = async projectId => {
  log.debug('provider:getAllLocationsByBook projectId:', projectId);

  const res = await axios.get(`${baseApi}/projects/locations/${projectId}`);
  return res.data;
};

export const deleteBook = async bookId => {
  log.debug('provider:deleteBook:', bookId);

  const res = await axios.delete(`${baseApi}/projects/${bookId}`);
  return res.data;
};

export const updateUserPreference = async (name, value) => {
  log.debug('provider:updateUserPreference:', { name, value });

  const res = await axios.post(`${baseApi}/preferences`, { name, value });
  return res.data;
};

export const search = async ({bookId, searchText, area, pageSize, pageNumber}) => {
  log.debug('provider:search', bookId, searchText, area, pageSize, pageNumber);

  const req = {
    projectId: bookId,
    section: area || 'All',
    searchText,
    pageSize: pageSize || 10,
    pageNumber: pageNumber || 1,
  };

  const res = await axios.post(`${baseApi}/explorer/explore`, req);

  // Include request in results
  const searchResults = {
    ...req,
    ...res.data
  }

  return searchResults;
};

export const getStatistics = async (bookId) => {
  log.debug('provider:statistics', bookId);

  const res = await axios.get(`${baseApi}/statistics/${bookId}`);
  return res.data;
};

export const exportBook = async options => {
  const res = await axios.post(`${baseApi}/export`, options, {
    withCredentials: true,
    responseType: 'blob',
  });

  // var res = await axios.post(baseApi + '/export', options, {
  //   withCredentials: true,
  //   responseType: 'blob',
  // });
  if (res.status === 200) {
    // @ts-ignore
    if (!window.Cypress) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      var link = document.createElement('a');
      document.body.appendChild(link); // required in FF
      link.href = fileURL;
      link.download = options.fileName;
      // @ts-ignore
      link.target = '_self'; // required in FF
      link.click();
    }
    return { success: true, data: null };
  } else {
    return { success: false, message: '' };
  }
};

export const getTagListByBook = async bookId => {
  const res = await axios.get(`${baseApi}/scenes/taglist/project/${bookId}`);
  return res.data;
};

export const getScenesByTagName = async ({bookId, tagName}) => {
  const res = await axios.get(`${baseApi}/scenes/project/${bookId}/tag/${tagName}`);
  return res.data;
};