"use strict";
exports.__esModule = true;
exports.BookSchema = void 0;
var yup_1 = require("yup");
exports.BookSchema = yup_1.object({
    bookCover: yup_1.string().nullable()["default"](null),
    dateUpdated: yup_1.string().required()["default"](new Date().toISOString()),
    dateCreated: yup_1.string().required()["default"](new Date().toISOString()),
    genre: yup_1.string().required().nullable()["default"](''),
    history: yup_1.array()["default"]([]),
    projectId: yup_1.number().required()["default"](-1),
    sortOrder: yup_1.number().required()["default"](0),
    synopsis: yup_1.string()["default"](''),
    title: yup_1.string().required()["default"]('')
});
