"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function EditorAddIcon(props) {
    return (React.createElement("svg", __assign({ width: "20px", height: "20px", viewBox: "0 0 20 20" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("filter", { x: "-3.3%", y: "-3.2%", width: "106.7%", height: "109%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 8, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.1 0", "in": "shadowBlurOuter1" })),
            React.createElement("rect", { id: "prefix__c", x: 120, y: 393, width: 840, height: 621, rx: 8 })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v1286H0z", transform: "translate(-144 -890)" }),
            React.createElement("g", { transform: "translate(-144 -970)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__c" })),
            React.createElement("path", { d: "M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0010 0zm0 18a8 8 0 110-16 8 8 0 010 16zm3.5-9H11V6.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5V9H6.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H9v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V11h2.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z", fill: "#3498DB", fillRule: "nonzero" }))));
}
var MemoEditorAddIcon = React.memo(EditorAddIcon);
exports["default"] = MemoEditorAddIcon;
