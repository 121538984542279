"use strict";
exports.__esModule = true;
exports.tagState = exports.exportState = exports.searchState = exports.locationsState = exports.wordCount = exports.editorContentSaveStatusState = exports.editorContentState = exports.activeSceneState = exports.activeChapterState = exports.scenesState = exports.chaptersState = exports.timelineState = exports.statisticsState = exports.booksState = exports.navigationState = exports.userState = exports.activeBookState = void 0;
var recoil_1 = require("recoil");
var Book_1 = require("../contract/types/book/Book");
var Navigation_1 = require("../contract/types/nav/Navigation");
var Scene_1 = require("../contract/types/scene/Scene");
var draft_js_1 = require("draft-js");
var Chapter_1 = require("../contract/types/chapter/types/Chapter");
// Active book
exports.activeBookState = recoil_1.atom({
    key: 'activeBook',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: Book_1.BookSchema.cast(),
        validationErrors: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.userState = recoil_1.atom({
    key: 'user',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: {
            activeBookId: null,
            activeSceneId: null,
            email: null,
            firstName: null,
            imageUrl: null,
            isAuthenticated: false,
            lastName: null
        }
    }
});
exports.navigationState = recoil_1.atom({
    key: 'navigation',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: Navigation_1.NavigationSchema.cast()
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.booksState = recoil_1.atom({
    key: 'books',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.statisticsState = recoil_1.atom({
    key: 'statistics',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: {}
    }
});
exports.timelineState = recoil_1.atom({
    key: 'timeline',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: []
    }
});
// All chpaters in the book
exports.chaptersState = recoil_1.atom({
    key: 'chapters',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
// All scenes in active chapter
exports.scenesState = recoil_1.atom({
    key: 'scenes',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
// Currently active chapter
exports.activeChapterState = recoil_1.atom({
    key: 'activeChapter',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: Chapter_1.ChapterSchema.cast(),
        validationErrors: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
// Currently selected scene
exports.activeSceneState = recoil_1.atom({
    key: 'activeScene',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: Scene_1.SceneSchema.cast(),
        validationErrors: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.editorContentState = recoil_1.atom({
    key: 'editorContentState',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: draft_js_1.EditorState.createEmpty()
    }
});
exports.editorContentSaveStatusState = recoil_1.atom({
    key: 'editorContentSaveStateAtom',
    "default": {
        status: "SAVED" /* SAVED */,
        payload: null
    }
});
exports.wordCount = recoil_1.atom({
    key: 'wordCount',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: null
    }
});
// All locations for book
exports.locationsState = recoil_1.atom({
    key: 'locations',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.searchState = recoil_1.atom({
    key: 'search',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: null
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.exportState = recoil_1.atom({
    key: 'export',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: null
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
exports.tagState = recoil_1.atom({
    key: 'tags',
    "default": {
        status: "DEFAULT" /* DEFAULT */,
        payload: []
    },
    // @ts-ignore
    persistence_UNSTABLE: { type: 'log' }
});
