"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
var react_1 = __importStar(require("react"));
// Stupid string bool and naving iconcolorhover vs iconColorHover
// is to remove react console warnings!
function Icon(props) {
    var _a = __read(react_1.useState('false'), 2), ishover = _a[0], setisHover = _a[1];
    var iconcolor = '#B6C9DB';
    var iconcolorhover = '#ff851b';
    var circlecolor = '#F6F8FA';
    var circlecolorhover = '#FFF3E7';
    return (react_1["default"].createElement("div", { onMouseEnter: function () { return setisHover('true'); }, onMouseLeave: function () { return setisHover('false'); } }, react_1["default"].cloneElement(props.children, {
        ishover: ishover,
        iconcolor: iconcolor,
        iconcolorhover: iconcolorhover,
        circlecolor: circlecolor,
        circlecolorhover: circlecolorhover
    })));
}
var HeaderIcon = react_1["default"].memo(Icon);
exports["default"] = HeaderIcon;
