"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function TagsIcon(props) {
    return (React.createElement("svg", __assign({ width: "16px", height: "16px", viewBox: "0 0 16 16" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("filter", { x: "-3.3%", y: "-12.7%", width: "106.7%", height: "135.4%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 8, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.1 0", "in": "shadowBlurOuter1" })),
            React.createElement("rect", { id: "prefix__c", x: 120, y: 211, width: 840, height: 158, rx: 8 })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v1123H0z", transform: "translate(-516 -240)" }),
            React.createElement("g", { transform: "translate(-516 -320)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__c" })),
            React.createElement("path", { d: "M15.666 0h-6a.336.336 0 00-.235.097L.097 9.431a.334.334 0 000 .47l6 6a.333.333 0 00.472.002l9.333-9.334A.336.336 0 0016 6.333v-6A.333.333 0 0015.666 0zm-3.333 5.333c-.92 0-1.666-.748-1.666-1.666 0-.919.747-1.667 1.666-1.667.92 0 1.667.748 1.667 1.667 0 .918-.748 1.666-1.667 1.666z", fill: "#3498DB", fillOpacity: 0.75, fillRule: "nonzero" }))));
}
var MemoTagsIcon = React.memo(TagsIcon);
exports["default"] = MemoTagsIcon;
