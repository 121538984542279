"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function CaretDownIcon(props) {
    return (React.createElement("svg", __assign({ width: "8px", height: "5px", viewBox: "0 0 8 5" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("filter", { x: "0%", y: "-.6%", width: "100.1%", height: "102.5%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.920585337 0 0 0 0 0.931783421 0 0 0 0 0.950973732 0 0 0 1 0", "in": "shadowOffsetOuter1" })),
            React.createElement("path", { id: "prefix__c", d: "M0 0h1440v80H0z" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v547H0z", transform: "translate(-1276 -39)" }),
            React.createElement("g", { transform: "translate(-1276 -39)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__c" })),
            React.createElement("path", { d: "M.138 1.06A.519.519 0 010 .705C0 .572.05.444.138.35l.187-.2A.442.442 0 01.66 0h6.68a.442.442 0 01.335.15l.187.2A.519.519 0 018 .705c0 .133-.05.261-.138.355L4.326 4.85A.434.434 0 014 5.002a.434.434 0 01-.326-.152L.138 1.06z", fill: props.color || '#3498DB', fillRule: "nonzero" }))));
}
var MemoCaretDownIcon = React.memo(CaretDownIcon);
exports["default"] = MemoCaretDownIcon;
