"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function LocationsIcon_gray(props) {
    return (React.createElement("svg", __assign({ width: 18, height: 20, viewBox: "0 0 16 20.001" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: 0.5, y1: 0, x2: 0.5, y2: 1, id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("linearGradient", { xlinkHref: "#prefix__a", id: "prefix__c", x1: 680.412, y1: 0, x2: 680.412, y2: 1360.823, gradientTransform: "scale(1.05818 .94502)", gradientUnits: "userSpaceOnUse" }),
            React.createElement("linearGradient", { xlinkHref: "#prefix__a", id: "prefix__b", x1: 680.412, y1: 0, x2: 680.412, y2: 1360.823, gradientTransform: "scale(1.05818 .94502)", gradientUnits: "userSpaceOnUse" }),
            React.createElement("filter", { x: -0.053, y: -0.015, width: 1.107, height: 1.033, filterUnits: "objectBoundingBox", id: "prefix__d" },
                React.createElement("feOffset", { dy: 4, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.15 0", "in": "shadowBlurOuter1" })),
            React.createElement("filter", { id: "prefix__f", x: 0, y: 0, width: 1, height: 1, colorInterpolationFilters: "sRGB" },
                React.createElement("feColorMatrix", { values: "0.21 0.72 0.072 0 0 0.21 0.72 0.072 0 0 0.21 0.72 0.072 0 0 0 0 0 1 0" })),
            React.createElement("path", { id: "prefix__e", d: "M1080 80h360v1286h-360z" })),
        React.createElement("path", { fill: "url(#prefix__c)", d: "M0 0h1440v1286H0z", transform: "translate(-1187 -562)", fillRule: "evenodd" }),
        React.createElement("g", { transform: "translate(-1104 -632)", fill: "none", fillRule: "evenodd" },
            React.createElement("use", { fill: "#000", filter: "url(#prefix__d)", xlinkHref: "#prefix__e", width: "100%", height: "100%" }),
            React.createElement("use", { fill: "#fcfdfe", xlinkHref: "#prefix__e", width: "100%", height: "100%" })),
        React.createElement("path", { d: "M8 0a8 8 0 00-8 8c0 6.22 5.05 6.17 6.26 9.78l.51 1.54a1 1 0 001 .68h.56a1 1 0 001-.68l.51-1.54C11 14.17 16 14.22 16 8a8 8 0 00-8-8zm0 11a3 3 0 110-6 3 3 0 010 6z", filter: "url(#prefix__f)", fill: "#ff851b" })));
}
var MemoLocationsIcon_gray = React.memo(LocationsIcon_gray);
exports["default"] = MemoLocationsIcon_gray;
