"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function DatePickerIcon(props) {
    return (React.createElement("svg", __assign({ width: "16px", height: "16px", viewBox: "0 0 16 16" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("filter", { x: "-3.3%", y: "-12.7%", width: "106.7%", height: "135.4%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 8, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.1 0", "in": "shadowBlurOuter1" })),
            React.createElement("rect", { id: "prefix__c", x: 120, y: 211, width: 840, height: 158, rx: 8 })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v1123H0z", transform: "translate(-152 -240)" }),
            React.createElement("g", { transform: "translate(-152 -320)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__c" })),
            React.createElement("path", { d: "M16 3.368c0-.93-.796-1.684-1.778-1.684h-.889V.421A.433.433 0 0012.89 0H12a.433.433 0 00-.444.421v1.263H4.444V.421A.433.433 0 004 0h-.889a.433.433 0 00-.444.421v1.263h-.89C.797 1.684 0 2.438 0 3.368v10.948C0 15.246.796 16 1.778 16h12.444c.982 0 1.778-.754 1.778-1.684V3.368zm-1.778 10.948H1.778V5.053h12.444v9.263zm-6.666-7.58a.433.433 0 00-.445.422V8c0 .233.2.421.445.421H12A.433.433 0 0012.444 8v-.842A.433.433 0 0012 6.737H7.556zM4 8.422h.889A.433.433 0 005.333 8v-.842a.433.433 0 00-.444-.421H4a.433.433 0 00-.444.42V8c0 .233.199.421.444.421zm4.444 3.368a.433.433 0 00.445-.42v-.843a.433.433 0 00-.445-.42H4a.433.433 0 00-.444.42v.842c0 .233.199.421.444.421h4.444zm2.223-.42c0 .232.199.42.444.42H12a.433.433 0 00.444-.42v-.843a.433.433 0 00-.444-.42h-.889a.433.433 0 00-.444.42v.842z", fill: "#3498DB", fillOpacity: 0.75, fillRule: "nonzero" }))));
}
var MemoDatePickerIcon = React.memo(DatePickerIcon);
exports["default"] = MemoDatePickerIcon;
