"use strict";
exports.__esModule = true;
exports.ListItemSchema = void 0;
var yup_1 = require("yup");
exports.ListItemSchema = yup_1.object({
    listId: yup_1.number().min(1, 'listId is a required value').required()["default"](-1),
    completed: yup_1.boolean().required()["default"](false),
    content: yup_1.string().nullable()["default"](null),
    dateTime: yup_1.string().nullable()["default"](null),
    locked: yup_1.boolean().required()["default"](false),
    purpose: yup_1.string()["default"](''),
    listItemId: yup_1.number().required()["default"](-1),
    sortOrder: yup_1.number().required(),
    tags: yup_1.array().notRequired(),
    title: yup_1.string().required()
});
