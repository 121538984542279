"use strict";
exports.__esModule = true;
exports.SortableItemType = void 0;
var SortableItemType;
(function (SortableItemType) {
    SortableItemType["SCENE"] = "SCENE";
    SortableItemType["CHAPTER"] = "CHAPTER";
    SortableItemType["CHARACTER"] = "CHARACTER";
    SortableItemType["LIST_ITEM"] = "LIST_ITEM";
})(SortableItemType = exports.SortableItemType || (exports.SortableItemType = {}));
