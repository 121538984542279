"use strict";
exports.__esModule = true;
exports.setStoreValue = exports.getStoreValue = exports.initializeState = void 0;
// import { useRecoilTransactionObserver_UNSTABLE } from 'recoil';
var atoms_1 = require("../store/atoms");
var initializeState = function (_a) {
    var set = _a.set, getLoadable = _a.getLoadable;
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        if (key === 'user') {
            var authUser = getStoreValue('user');
            var atom = {
                payload: authUser
            };
            set(atoms_1.userState, atom);
        }
    }
};
exports.initializeState = initializeState;
function getStoreValue(key) {
    return JSON.parse(localStorage.getItem(key));
}
exports.getStoreValue = getStoreValue;
function setStoreValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
    if (key === 'user') {
    }
    return value;
}
exports.setStoreValue = setStoreValue;
