"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function EmptyTimelineIcon(props) {
    return (React.createElement("svg", __assign({ width: 74, height: 59 }, props),
        React.createElement("g", { transform: "translate(-248 -837)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "#FFF", d: "M0 547h1440v1110H0z" }),
            React.createElement("rect", { fillOpacity: 0.5, fill: "#EFF7FF", x: 156, y: 691, width: 264, height: 574, rx: 8 }),
            React.createElement("path", { d: "M281.364 872.182a16.364 16.364 0 10-24.982 13.89 7.273 7.273 0 013.163 3.855l1.128 3.418a3.636 3.636 0 003.636 2.473h2.036a3.636 3.636 0 003.637-2.473l.473-3.418a7.273 7.273 0 013.018-3.854 16.364 16.364 0 007.89-13.891zM265 879.455a7.273 7.273 0 110-14.546 7.273 7.273 0 010 14.546zm39.055-22.11a7.273 7.273 0 012.654 3.637l1.527 4.545c.248.735.934 1.23 1.71 1.237h1.018a1.818 1.818 0 001.709-1.237l1.418-4.582a7.273 7.273 0 012.654-3.636 10.91 10.91 0 10-12.8 0l.11.036zm6.4-12.436a3.636 3.636 0 110 7.273 3.636 3.636 0 010-7.273zm-10.91 21.818l-1.818-1.563h2.473a.91.91 0 00.727-.364.982.982 0 000-.836l-1.127-3.346a1.855 1.855 0 00-1.71-1.236h-6.072a3.636 3.636 0 00-3.309 2.182l-.29.69a3.636 3.636 0 00.69 3.928l20.255 22.363h-28.4a3.636 3.636 0 00-3.637 2.473l-.8 2.4a1.818 1.818 0 001.71 2.4h39.2a3.636 3.636 0 003.345-2.218l.29-.69c.573-1.33.301-2.873-.69-3.928l-20.837-22.255z", fill: "#DCE7F1", fillRule: "nonzero" }))));
}
var MemoEmptyTimelineIcon = React.memo(EmptyTimelineIcon);
exports["default"] = MemoEmptyTimelineIcon;
