"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
exports.BlogPostIntro = void 0;
var react_1 = __importStar(require("react"));
var BlogExerptTitle_1 = require("../../elements/BlogExerptTitle");
var Description_1 = require("../../elements/Description");
var BlogIntroImage_1 = require("../../elements/BlogIntroImage");
var Button_1 = require("../../elements/buttons/Button");
exports.BlogPostIntro = function (props) {
    var _a = __read(react_1.useState([]), 2), blogs = _a[0], setBlogs = _a[1];
    return (react_1["default"].createElement("div", null,
        react_1["default"].createElement(BlogExerptTitle_1.BlogExerptTitle, { primary: true }, props.title),
        react_1["default"].createElement(BlogIntroImage_1.BlogIntroImage, { src: props.imageUrl }),
        react_1["default"].createElement(Description_1.Description, null, props.description),
        react_1["default"].createElement(Button_1.Button, { buttonType: Button_1.ButtonType.Primary }, "Click Me")));
};
exports["default"] = exports.BlogPostIntro;
