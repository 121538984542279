"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function DragUpDownIcon(props) {
    return (React.createElement("svg", __assign({ width: "7px", height: "20px", viewBox: "0 0 7 20" }, props),
        React.createElement("path", { d: "M.493 3.994h2v2.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2.5h2a.5.5 0 00.36-.85l-3-3a.51.51 0 00-.71 0l-3 3a.5.5 0 00.35.85zm6 12h-2v-2.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v2.5h-2a.5.5 0 00-.35.85l3 3a.51.51 0 00.71 0l3-3a.5.5 0 00-.36-.85z", fill: "#B6C9DB", fillRule: "nonzero" })));
}
var MemoDragUpDownIcon = React.memo(DragUpDownIcon);
exports["default"] = MemoDragUpDownIcon;
