"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function TelescopeIcon(props) {
    return (React.createElement("svg", __assign({ width: "30px", height: "30px", viewBox: "0 0 24 24", fill: "none" }, props),
        React.createElement("path", { strokeLinejoin: "round", strokeLinecap: "round", strokeMiterlimit: 10, stroke: "#221b38", d: "M16 22l-4.5-8L7 22" }),
        React.createElement("path", { strokeLinejoin: "round", strokeLinecap: "round", strokeMiterlimit: 10, stroke: "#221b38", fill: "#9b9b9b", d: "M10 11.86V14h3v-3.23l-3 1.09z", clipRule: "evenodd", fillRule: "evenodd" }),
        React.createElement("path", { strokeLinecap: "round", strokeMiterlimit: 10, stroke: "#221b38", d: "M2 8.94l2.04 5.62" }),
        React.createElement("path", { strokeLinecap: "round", strokeMiterlimit: 10, stroke: "#221b38", fill: "#9b9b9b", d: "M2.51 10.344l1.023 2.81 4.68-1.704L7.19 8.64l-4.68 1.704z" }),
        React.createElement("path", { strokeLinecap: "round", strokeMiterlimit: 10, stroke: "#221b38", fill: "#3498db", d: "M17.15 3.96l1.7 4.68-9.83 3.58a.501.501 0 01-.64-.3L7.02 8.18c-.09-.26.04-.55.3-.64l9.83-3.58z", clipRule: "evenodd", fillRule: "evenodd" }),
        React.createElement("path", { strokeLinecap: "round", strokeMiterlimit: 10, stroke: "#221b38", fill: "#9b9b9b", d: "M19.62 2L22 8.55l-1.87.68a.998.998 0 01-1.28-.6l-1.7-4.67a.998.998 0 01.6-1.28L19.62 2z", clipRule: "evenodd", fillRule: "evenodd" })));
}
var MemoTelescopeIcon = React.memo(TelescopeIcon);
exports["default"] = MemoTelescopeIcon;
