"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
exports.__esModule = true;
var react_1 = require("react");
function useOnWindowSize() {
    var _a = __read(react_1.useState([0, 0]), 2), windowSize = _a[0], setWindowSize = _a[1];
    var _b = __read(react_1.useState(0), 2), scrollPosition = _b[0], setSrollPosition = _b[1];
    var handleScroll = function () {
        var position = window.pageYOffset;
        setSrollPosition(position);
    };
    var viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    react_1.useEffect(function () {
        function updateSize() {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        window.addEventListener('scroll', handleScroll, { passive: true });
        return function () {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', updateSize);
        };
    }, []);
    return { width: windowSize[0], height: windowSize[1], viewHeight: viewHeight, scrollPosition: scrollPosition };
}
exports["default"] = useOnWindowSize;
