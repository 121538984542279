"use strict";
exports.__esModule = true;
exports.ALL_GENRES = exports.NON_FICTIONS = exports.FICTIONS = void 0;
var FICTIONS = [
    'Comic/Graphic Novel',
    'Crime/Detective',
    'Fable',
    'Fairy Tale',
    'Fantasy',
    'Humor',
    'Magic',
    'Mystery',
    'Mythology',
    'Romance',
    'Science Fiction',
    'Short Story',
    'Suspense/Thriller',
    'Other Fiction',
];
exports.FICTIONS = FICTIONS;
var NON_FICTIONS = [
    'Biography',
    'Essay',
    'Memoir',
    'Narrative',
    'Reference book',
    'Self-help book',
    'Textbook',
    'Other Non-Fiction',
];
exports.NON_FICTIONS = NON_FICTIONS;
var ALL_GENRES = [].concat(FICTIONS, NON_FICTIONS);
exports.ALL_GENRES = ALL_GENRES;
