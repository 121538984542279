"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function EditorBulletsIcon(props) {
    return (React.createElement("svg", __assign({ width: "19px", height: "16px", viewBox: "0 0 19 16" }, props),
        React.createElement("defs", null,
            React.createElement("filter", { x: "-3.3%", y: "-5.2%", width: "106.7%", height: "114.5%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 8, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.1 0", "in": "shadowBlurOuter1" })),
            React.createElement("filter", { x: "-.1%", y: "-.9%", width: "100.1%", height: "101.8%", filterUnits: "objectBoundingBox", id: "prefix__e" },
                React.createElement("feOffset", { dy: -1, "in": "SourceAlpha", result: "shadowOffsetInner1" }),
                React.createElement("feComposite", { "in": "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: -1, k3: 1, result: "shadowInnerInner1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.964705882 0 0 0 0 0.97254902 0 0 0 0 0.980392157 0 0 0 1 0", "in": "shadowInnerInner1", result: "shadowMatrixInner1" }),
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetInner2" }),
                React.createElement("feComposite", { "in": "shadowOffsetInner2", in2: "SourceAlpha", operator: "arithmetic", k2: -1, k3: 1, result: "shadowInnerInner2" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0", "in": "shadowInnerInner2", result: "shadowMatrixInner2" }),
                React.createElement("feMerge", null,
                    React.createElement("feMergeNode", { "in": "shadowMatrixInner1" }),
                    React.createElement("feMergeNode", { "in": "shadowMatrixInner2" }))),
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("rect", { id: "prefix__c", x: 120, y: 529, width: 840, height: 385, rx: 8 }),
            React.createElement("path", { d: "M128 529h824a8 8 0 018 8v48H120v-48a8 8 0 018-8z", id: "prefix__d" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "transparent", d: "M-372-549h1440V581H-372z" }),
            React.createElement("path", { d: "M0 0h1440v1050H0z", transform: "translate(-372 -469)" }),
            React.createElement("g", { transform: "translate(-372 -549)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "transparent", xlinkHref: "#prefix__c" })),
            React.createElement("g", { transform: "translate(-372 -549)" },
                React.createElement("use", { fillOpacity: 0.101, fill: "#EFF2F6", xlinkHref: "#prefix__d" }),
                React.createElement("use", { fill: "#000", filter: "url(#prefix__e)", xlinkHref: "#prefix__d" })),
            React.createElement("path", { d: "M-2-4h24v24H-2z" }),
            React.createElement("path", { d: "M2 6.5C1.17 6.5.5 7.17.5 8S1.17 9.5 2 9.5 3.5 8.83 3.5 8 2.83 6.5 2 6.5zm0-6C1.17.5.5 1.17.5 2S1.17 3.5 2 3.5 3.5 2.83 3.5 2 2.83.5 2 .5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM5 15h14v-2H5v2zm0-6h14V7H5v2zm0-8v2h14V1H5z", fill: "#8A9AA5", fillRule: "nonzero" }))));
}
var MemoEditorBulletsIcon = React.memo(EditorBulletsIcon);
exports["default"] = MemoEditorBulletsIcon;
