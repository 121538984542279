"use strict";
exports.__esModule = true;
exports.SceneSchema = void 0;
var yup_1 = require("yup");
exports.SceneSchema = yup_1.object({
    chapterId: yup_1.number()
        .min(1, 'chapter is a required value')
        .required()["default"](-1),
    completed: yup_1.boolean().required()["default"](false),
    content: yup_1.string().nullable()["default"](null),
    dateTime: yup_1.string().nullable()["default"](null),
    dateUpdated: yup_1.string().required()["default"](new Date().toISOString()),
    dateCreated: yup_1.string().required()["default"](new Date().toISOString()),
    locked: yup_1.boolean().required()["default"](false),
    purpose: yup_1.string()["default"](''),
    locationId: yup_1.number().nullable()["default"](-1),
    sceneId: yup_1.number().required()["default"](-1),
    sortOrder: yup_1.number().required(),
    tags: yup_1.array().notRequired(),
    title: yup_1.string().required()
});
