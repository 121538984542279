"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.diff = exports.getChanges = void 0;
var getChanges = function (o1, o2) {
    var diff = Object.keys(o2).reduce(function (diff, key) {
        var _a;
        if (o1[key] === o2[key])
            return diff;
        return __assign(__assign({}, diff), (_a = {}, _a[key] = o2[key], _a));
    }, {});
    return diff;
};
exports.getChanges = getChanges;
function diff(obj1, obj2) {
    if (!obj1 && !obj2) {
        return null;
    }
    if (obj1 && !obj2) {
        return obj1;
    }
    if (!obj1 && obj2) {
        return obj2;
    }
    var result = {};
    if (Object.is(obj1, obj2)) {
        return undefined;
    }
    if (!obj2 || typeof obj2 !== 'object') {
        return obj2;
    }
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(function (key) {
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
            result[key] = obj2[key];
        }
        if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
            var value = diff(obj1[key], obj2[key]);
            if (value !== undefined) {
                result[key] = value;
            }
        }
    });
    return result;
}
exports.diff = diff;
