"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function ArrowDownWithCircleIcon(props) {
    return (React.createElement("svg", __assign({ width: "20px", height: "20px", viewBox: "0 0 20 20" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" }))),
        React.createElement("g", { transform: "translate(-1248 -431)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v547H0z" }),
            React.createElement("rect", { stroke: "#DCE7F1", fillOpacity: 0.35, fill: "#FFF", x: 1020.5, y: 416.5, width: 263, height: 50, rx: 8 }),
            React.createElement("path", { d: "M1258 451c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10a10 10 0 0010 10zm0-18a8 8 0 110 16 8 8 0 010-16zm-.72 13.78a.77.77 0 00.53.22h.38a.75.75 0 00.53-.22l3.13-3.14a.5.5 0 000-.71l-.7-.71a.5.5 0 00-.71 0l-1.44 1.45v-8.17a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v8.17l-1.44-1.44a.49.49 0 00-.7 0l-.71.71a.5.5 0 000 .71l3.13 3.13z", fill: "#3498DB", fillRule: "nonzero" }))));
}
var MemoArrowDownWithCircleIcon = React.memo(ArrowDownWithCircleIcon);
exports["default"] = MemoArrowDownWithCircleIcon;
