"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function TrashcanIcon(props) {
    return (React.createElement("svg", __assign({ width: "12px", height: "16px", viewBox: "0 0 12 16" }, props),
        React.createElement("g", { transform: "translate(-846 -1224)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "#FAFCFE", d: "M0 0h1440v1493H0z" }),
            React.createElement("rect", { strokeOpacity: 0.352, stroke: "#D44E48", fillOpacity: 0.5, fill: "#FFF", x: 444.5, y: 1179.5, width: 551, height: 105, rx: 8 }),
            React.createElement("rect", { fillOpacity: 0.05, fill: "#D44E48", x: 829, y: 1212, width: 135, height: 40, rx: 20 }),
            React.createElement("g", { fill: "#D44E48", fillRule: "nonzero" },
                React.createElement("path", { d: "M858 1226v.8c0 .22-.168.4-.375.4h-11.25c-.207 0-.375-.18-.375-.4v-.8c0-.22.168-.4.375-.4h3.375v-.8c0-.442.336-.8.75-.8h3c.414 0 .75.358.75.8v.8h3.375c.207 0 .375.18.375.4zM847.403 1238.512c.055.84.71 1.49 1.5 1.488h6.21c.789.002 1.444-.648 1.5-1.488l.637-9.712h-10.5l.653 9.712z" })))));
}
var MemoTrashcanIcon = React.memo(TrashcanIcon);
exports["default"] = MemoTrashcanIcon;
