"use strict";
exports.__esModule = true;
exports.toSafeTruncate = void 0;
exports.toSafeTruncate = function (input, maxLength, includeEllipse) {
    if (!input) {
        return input;
    }
    var ret = input;
    var willTruncate = input.toString().length > maxLength;
    if (willTruncate) {
        ret = input.substring(0, maxLength);
    }
    return willTruncate ? ret + "..." : ret;
};
