"use strict";
exports.__esModule = true;
exports.NavigationSchema = void 0;
var yup_1 = require("yup");
var ListItem_1 = require("../lists/ListItem");
var Scene_1 = require("../scene/Scene");
var Chapter_1 = require("../chapter/types/Chapter");
exports.NavigationSchema = yup_1.object({
    selectedChapterId: yup_1.number().required()["default"](-1),
    selectedSceneId: yup_1.number().required()["default"](-1),
    selectedListId: yup_1.number().required()["default"](-1),
    selectedListItemId: yup_1.number().required()["default"](-1),
    chapters: yup_1.array().of(Chapter_1.ChapterSchema),
    scenes: yup_1.array().of(Scene_1.SceneSchema),
    lists: yup_1.array().notRequired(),
    listItems: yup_1.array().of(ListItem_1.ListItemSchema).notRequired()
});
