import React from 'react';
import ReactDOM from 'react-dom';
import AppShell from './AppShell';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from '@tooleybook/ui-lib/lib/src/core/ErrorHandling';

ReactDOM.render(
  <ErrorBoundary>
    <AppShell />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
