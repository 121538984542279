"use strict";
exports.__esModule = true;
var react_1 = require("react");
var useOutsideClick = function (ref, callback) {
    var handleClick = function (e) {
        if (!!ref && ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };
    react_1.useEffect(function () {
        document.addEventListener('click', handleClick);
        return function () {
            document.removeEventListener('click', handleClick);
        };
    }, [ref, callback]);
};
exports["default"] = useOutsideClick;
