"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function GearIconAlt(props) {
    return (React.createElement("svg", __assign({ width: "40px", height: "40px", viewBox: "0 0 40 40" }, props),
        React.createElement("defs", null,
            React.createElement("filter", { x: "0%", y: "-.6%", width: "100.1%", height: "102.5%", filterUnits: "objectBoundingBox", id: "prefix__a" },
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.920585337 0 0 0 0 0.931783421 0 0 0 0 0.950973732 0 0 0 1 0", "in": "shadowOffsetOuter1" })),
            React.createElement("path", { id: "prefix__b", d: "M0 0h1440v80H0z" })),
        React.createElement("g", { transform: "translate(-1320 -20)", fill: "none", fillRule: "evenodd" },
            React.createElement("use", { fill: "#000", filter: "url(#prefix__a)", xlinkHref: "#prefix__b" }),
            React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__b" }),
            React.createElement("circle", { fill: props.ishover === 'true'
                    ? props.circlecolorhover
                    : props.circlecolor, cx: 1340, cy: 40, r: 20 }),
            React.createElement("g", { fill: props.ishover === 'true' ? props.iconcolorhover : props.iconcolor },
                React.createElement("path", { d: "M1349.605 42.735l-.854-.66a1.952 1.952 0 01-.768-1.644V40v-.43c-.034-.632.25-1.24.768-1.644l.854-.661a.961.961 0 00.256-1.273l-1.238-2.004c-.262-.418-.806-.6-1.291-.43l-1.067.36c-.63.22-1.334.15-1.9-.19a5.951 5.951 0 00-.79-.431c-.6-.29-1.02-.828-1.131-1.453l-.182-1.002c-.084-.49-.538-.848-1.067-.842h-2.454c-.529-.006-.983.352-1.068.842l-.181 1.002a2.012 2.012 0 01-1.089 1.453 5.951 5.951 0 00-.79.43c-.565.341-1.27.412-1.899.19l-1.067-.36c-.479-.158-1.01.022-1.27.431l-1.238 2.004a.961.961 0 00.256 1.273l.854.66c.518.404.802 1.013.768 1.644v.861c.034.632-.25 1.24-.768 1.644l-.854.661a.961.961 0 00-.256 1.273l1.238 2.004c.262.418.806.6 1.291.43l1.067-.36a2.255 2.255 0 011.9.19c.251.163.515.307.79.431.6.29 1.02.828 1.131 1.453l.182 1.002c.084.49.538.848 1.067.842h2.454c.529.006.983-.352 1.068-.842l.181-1.002a2.019 2.019 0 011.131-1.453c.275-.124.54-.268.79-.43a2.255 2.255 0 011.9-.19l1.067.36c.466.137.971-.04 1.227-.431l1.238-2.004a.961.961 0 00-.256-1.273zm-9.605.271c-1.768 0-3.202-1.346-3.202-3.006 0-1.66 1.434-3.006 3.202-3.006s3.202 1.346 3.202 3.006c0 .797-.338 1.562-.938 2.126a3.31 3.31 0 01-2.264.88z", fillRule: "nonzero" })))));
}
var MemoGearIconAlt = React.memo(GearIconAlt);
exports["default"] = MemoGearIconAlt;
