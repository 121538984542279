"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function SearchIcon(props) {
    return (React.createElement("svg", __assign({ width: "18px", height: "17.98px", viewBox: "0 0 19 18" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("filter", { x: "0%", y: "-.6%", width: "100.1%", height: "102.5%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.920585337 0 0 0 0 0.931783421 0 0 0 0 0.950973732 0 0 0 1 0", "in": "shadowOffsetOuter1" })),
            React.createElement("path", { id: "prefix__c", d: "M0 0h1440v80H0z" })),
        React.createElement("g", { transform: "translate(-439 -31)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v547H0z" }),
            React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
            React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__c" }),
            React.createElement("rect", { fillOpacity: 0.2, fill: "#DCE7F1", x: 423, y: 16, width: 580, height: 48, rx: 24 }),
            React.createElement("g", { fill: "#B9C5CE", fillRule: "nonzero" },
                React.createElement("path", { d: "M456.875 47.44l-4-4a2.44 2.44 0 00-.43-.35l-1-.69a7 7 0 10-5.42 2.6 7 7 0 004.37-1.53l.75 1c.089.129.19.25.3.36l4 4a.5.5 0 00.71 0l.7-.7a.5.5 0 00.02-.69zM446.025 43a5 5 0 110-10 5 5 0 010 10z" })))));
}
var MemoSearchIcon = React.memo(SearchIcon);
exports["default"] = MemoSearchIcon;
