"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function TooleyBookLogo(props) {
    return (React.createElement("svg", __assign({ width: 171, height: 32 }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("filter", { x: "0%", y: "-.6%", width: "100.1%", height: "102.5%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.920585337 0 0 0 0 0.931783421 0 0 0 0 0.950973732 0 0 0 1 0", "in": "shadowOffsetOuter1" })),
            React.createElement("path", { id: "prefix__c", d: "M0 0h1440v80H0z" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M0 0h1440v547H0z", transform: "translate(-156 -24)" }),
            React.createElement("g", { transform: "translate(-156 -24)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__c" })),
            React.createElement("g", { fill: "#3498DB" },
                React.createElement("path", { d: "M143.988 3.005c.018-.001.042-.02 0 0M20.944 20.665c-2.545 0-3.855-1.895-3.892-5.647 0-3.825 1.31-5.756 3.93-5.756 2.544 0 3.816 1.858 3.816 5.61-.037 3.826-1.31 5.793-3.854 5.793M20.98 5C14.994 5 12 8.315 12 15.018 12 21.648 14.994 25 20.981 25 26.97 25 30 21.648 30 15.018 30 8.315 26.969 5 20.981 5M38.944 20.665c-2.545 0-3.855-1.895-3.892-5.647 0-3.825 1.31-5.756 3.93-5.756 2.544 0 3.816 1.858 3.816 5.61-.037 3.826-1.31 5.793-3.854 5.793M38.98 5C32.994 5 30 8.315 30 15.018 30 21.648 32.994 25 38.981 25 44.97 25 48 21.648 48 15.018 48 8.315 44.969 5 38.981 5M52.594 0H50v25h5V2.39C55 1.103 53.91 0 52.594 0M62.214 12.832c.375-2.55 1.65-3.861 3.789-3.861 2.138 0 3.376 1.311 3.676 3.861h-7.465zM66.078 5C60.038 5.036 57 8.315 57 14.872c0 6.558 3.001 9.946 9.003 10.128 4.877 0 7.577-2.259 8.665-6.339h-3.676c-.675 0-1.238.219-1.688.692-.975.911-2.063 1.385-3.226 1.385-2.438 0-3.751-1.494-3.939-4.445h12.83C75.343 8.753 72.38 5 66.078 5zM87.429 7.66l-3.286 11.305L80.393 6H75l6.429 18.264c.75 2.472-.179 3.744-2.786 3.744H77.32v3.85c.143.107.786.142 2 .142 3.929 0 5.072-1.06 6.215-4.133L93 6h-3.322c-1.214 0-1.964.565-2.25 1.66M103.89 20.894c-2.768 0-4.08-1.963-4.044-5.85.146-3.707 1.494-5.524 4.117-5.524 2.624 0 3.972 1.817 4.082 5.523 0 3.888-1.385 5.85-4.154 5.85m1.42-15.697c-2.404 0-4.226.981-5.465 2.98V2.435c0-1.6-.801-2.435-2.405-2.435H95v16.388c0 5.777 3.024 8.648 9.037 8.612 5.975-.11 8.963-3.343 8.963-9.738 0-6.541-2.55-9.884-7.688-10.066M164.148 13.456l6.778-7.206H165l-6.074 6.912V2.39c0-1.287-1.074-2.39-2.37-2.39H154v25h4.926v-7.096c0-.514.26-.845.778-.956.63 0 1.26.552 1.889 1.655L165.074 25H171l-6.852-11.544zM132.139 19.84c.037-.295.657-4.76 4.027-8.246C140.356 7.263 141.265 6.457 143 5c0 0-1.735.59-7.323 6.151-3.31 3.294-4.204 6.098-4.677 10.849a8.266 8.266 0 001.139-2.16" }),
                React.createElement("path", { d: "M124.653 21.606c-2.462 0-3.73-1.92-3.766-5.722 0-3.877 1.268-5.834 3.802-5.834 2.462 0 3.693 1.883 3.693 5.686-.037 3.877-1.268 5.87-3.73 5.87m22.13-15.628a15.212 15.212 0 00.338-.866l.016-.043.008-.021.008-.028.005-.018.036-.142c.023-.095.045-.192.065-.29a3.091 3.091 0 00-.655-2.41c-.132-.177-.297-.323-.462-.47a3.296 3.296 0 00-.565-.36 2.988 2.988 0 00-1.337-.327c-.24-.014-.47.027-.718.07a3.806 3.806 0 00-.672.24l-.091.043-.046.021-.14.078-.172.096-.349.192c-.473.255-.963.526-1.452.813-.988.564-1.972 1.2-2.929 1.869a39.786 39.786 0 00-2.749 2.145c-.225.189-.436.37-.641.554l-.308.278c-.12.108-.217.204-.326.306a16.266 16.266 0 00-1.61 1.719c-1.395-2.468-3.849-3.696-7.347-3.696-5.793 0-8.689 3.36-8.689 10.153 0 6.72 2.896 10.116 8.689 10.116 2.372 0 4.263-.572 5.67-1.707l.494-3.882s-3.556-5.416 4.006-11.798c3.986-3.364 6.696-4.805 8.495-5.922 1.388-.86 3.09.432 2.68 2.034-.529 2.057-1.637 4.537-3.85 7.143 0 0 .117-.086.33-.256.106-.085.236-.192.385-.322a13.691 13.691 0 001.26-1.233c.04-.002.076-.007.116-.007 2.462 0 3.693 1.883 3.693 5.686-.037 3.877-1.267 5.87-3.73 5.87-2.461 0-3.728-1.92-3.764-5.722 0-.826.059-1.562.174-2.213-2.03 2.37-3.59 4.136-4.813 5.325.812 4.654 3.624 7.004 8.44 7.004 5.792 0 8.725-3.397 8.725-10.116 0-5.73-2.087-9.018-6.217-9.906" }),
                React.createElement("path", { d: "M137 14.065l3-.065-1.024 2zM142 9l-2 .069L141.233 11c.276-.672.531-1.34.767-2M137 5.553L137.408 7 139 5c-.636.17-1.303.354-2 .553M133 13v-3c-.758.489-1.422.97-2 1.442L133 13zM7.52 9.086c1.39 0 2.48-1.034 2.48-2.438V5.577H6.994V2.475C6.994 1.182 5.904 0 4.59 0H1.997v5.577H0v3.509h1.997v12.262c-.037 2.179.864 3.324 2.743 3.508 1.879.185 3.644.185 5.26.038v-3.73H8.46c-.977 0-1.466-.444-1.466-1.257V9.086h.526z" })))));
}
var MemoTooleyBookLogo = React.memo(TooleyBookLogo);
exports["default"] = MemoTooleyBookLogo;
