"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function CustomListIcon_gray(props) {
    return (React.createElement("svg", __assign({ width: 18, height: 20, viewBox: "0 0 18 14" }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("linearGradient", { xlinkHref: "#prefix__a", id: "prefix__c", x1: 680.412, y1: 0, x2: 680.412, y2: 1360.823, gradientTransform: "scale(1.05818 .94502)", gradientUnits: "userSpaceOnUse" }),
            React.createElement("linearGradient", { xlinkHref: "#prefix__a", id: "prefix__b", x1: 680.412, y1: 0, x2: 680.412, y2: 1360.823, gradientTransform: "scale(1.05818 .94502)", gradientUnits: "userSpaceOnUse" }),
            React.createElement("filter", { x: -0.053, y: -0.015, width: 1.107, height: 1.033, filterUnits: "objectBoundingBox", id: "prefix__d" },
                React.createElement("feOffset", { dy: 4, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.15 0", "in": "shadowBlurOuter1" })),
            React.createElement("filter", { id: "prefix__f", x: 0, y: 0, width: 1, height: 1, colorInterpolationFilters: "sRGB" },
                React.createElement("feColorMatrix", { values: "0.21 0.72 0.072 0 0 0.21 0.72 0.072 0 0 0.21 0.72 0.072 0 0 0 0 0 1 0" })),
            React.createElement("path", { id: "prefix__e", d: "M1080 80h360v1286h-360z" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "url(#prefix__c)", d: "M0 0h1440v1286H0z", transform: "translate(-1104 -750)" }),
            React.createElement("g", { transform: "translate(-1104 -830)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__d)", xlinkHref: "#prefix__e", width: "100%", height: "100%" }),
                React.createElement("use", { fill: "#fcfdfe", xlinkHref: "#prefix__e", width: "100%", height: "100%" })),
            React.createElement("path", { d: "M.5 0h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 0zm17 0h-13a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM0 6.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5zM17.5 6h-13a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM0 12.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5zm17.5-.5h-13a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z", filter: "url(#prefix__f)", fill: "#ff851b", fillRule: "nonzero" }))));
}
var MemoCustomListIcon_gray = React.memo(CustomListIcon_gray);
exports["default"] = MemoCustomListIcon_gray;
