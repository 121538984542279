"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
exports.__esModule = true;
exports.fetchBlogs = void 0;
var apollo_boost_1 = require("apollo-boost");
var GET_POSTS = apollo_boost_1.gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GET_POSTS(\n    $first: Int\n    $after: String\n    $where: RootQueryToPostConnectionWhereArgs\n  ) {\n    posts(first: $first, after: $after, where: $where) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        cursor\n        node {\n          id\n          title\n          date\n          excerpt\n          commentCount\n          link\n          featuredImage {\n            sourceUrl\n          }\n        }\n      }\n    }\n  }\n"], ["\n  query GET_POSTS(\n    $first: Int\n    $after: String\n    $where: RootQueryToPostConnectionWhereArgs\n  ) {\n    posts(first: $first, after: $after, where: $where) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        cursor\n        node {\n          id\n          title\n          date\n          excerpt\n          commentCount\n          link\n          featuredImage {\n            sourceUrl\n          }\n        }\n      }\n    }\n  }\n"])));
function fetchBlogs() {
    // const { loading, data } = useQuery(GET_POSTS);
    //   async function fetchBlogs() {
    //   }
    //   useEffect(() => {
    //     fetchBlogs();
    //   }, []);
    var loading = false;
    var data = [];
    return [data, loading];
}
exports.fetchBlogs = fetchBlogs;
var templateObject_1;
