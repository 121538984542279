"use strict";
exports.__esModule = true;
exports.sortObjectsByDate = void 0;
exports.sortObjectsByDate = function (objects, fieldName) {
    if (!objects || objects.length === 0) {
        return objects;
    }
    return objects
        .slice()
        .sort(function (a, b) {
        return new Date(b[fieldName]).getTime() - new Date(a[fieldName]).getTime();
    });
};
