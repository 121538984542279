"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.activeBookWithSortedHistoryState = exports.booksWithSortedHistoryState = void 0;
var recoil_1 = require("recoil");
var atoms_1 = require("./atoms");
var sortBy_1 = require("../utils/sort/sortBy");
exports.booksWithSortedHistoryState = recoil_1.selector({
    key: 'booksWithSortedHistoryState',
    get: function (_a) {
        var get = _a.get;
        var allBooks = get(atoms_1.booksState);
        var books = allBooks.payload;
        if (!books || (books === null || books === void 0 ? void 0 : books.length) === 0) {
            return allBooks;
        }
        var sortedHistory = books.map(function (b) {
            return __assign(__assign({}, b), { history: sortBy_1.sortObjectsByDate(b.history, 'dateUpdated') });
        });
        return {
            payload: sortedHistory,
            status: "HASVALUE" /* HASVALUE */
        };
    }
});
exports.activeBookWithSortedHistoryState = recoil_1.selector({
    key: 'activeBookWithSortedHistoryState',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var activeBook = get(atoms_1.activeBookState);
        var book = activeBook.payload;
        if (!book || ((_b = book.history) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            return activeBook;
        }
        var withSortedHistory = __assign(__assign({}, book), { history: sortBy_1.sortObjectsByDate(book.history, 'dateUpdated') });
        return {
            payload: withSortedHistory,
            status: "HASVALUE" /* HASVALUE */
        };
    }
});
