"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function EmptyBookImage(props) {
    return (React.createElement("svg", __assign({ width: 404, height: 240 }, props),
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "67.468%", y1: "-.588%", x2: "-1.749%", y2: "137%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FFF", stopOpacity: 0, offset: "0%" }),
                React.createElement("stop", { stopColor: "#9AF2C7", offset: "100%" })),
            React.createElement("linearGradient", { x1: "90.195%", y1: "-21.098%", x2: "-2.488%", y2: "188.659%", id: "prefix__b" },
                React.createElement("stop", { stopColor: "#FFF", stopOpacity: 0, offset: "0%" }),
                React.createElement("stop", { stopColor: "#9AF2C7", offset: "100%" })),
            React.createElement("linearGradient", { x1: "84.306%", y1: "-17.808%", x2: "3.972%", y2: "258%", id: "prefix__c" },
                React.createElement("stop", { stopColor: "#FFF", stopOpacity: 0, offset: "0%" }),
                React.createElement("stop", { stopColor: "#8DF", offset: "100%" })),
            React.createElement("linearGradient", { x1: "58.643%", y1: "1.284%", x2: "-.586%", y2: "196.324%", id: "prefix__d" },
                React.createElement("stop", { stopColor: "#FFF", stopOpacity: 0, offset: "0%" }),
                React.createElement("stop", { stopColor: "#8DF", offset: "100%" }))),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "#FAFCFE", d: "M-518-399H922v944H-518z" }),
            React.createElement("g", { opacity: 0.268 },
                React.createElement("path", { d: "M359.613 10.44L201.992 18.47 43.764 10.44a11.178 11.178 0 00-11.632 11.178v216.495h339.114V21.632a11.178 11.178 0 00-11.633-11.193z", fill: "#506472", fillRule: "nonzero" }),
                React.createElement("path", { d: "M359.613 10.44L201.992 18.47 43.764 10.44a11.178 11.178 0 00-11.632 11.178v216.495h339.114V21.632a11.178 11.178 0 00-11.633-11.193h0z", stroke: "#273B48", strokeWidth: 3.194, strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#8197A5", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M188.962 17.609h26.084v29.605h-26.084z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#C9AF9D", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M359.597 238.112H202V27.022l157.597-6.627zM202 238.112H44.403V20.395L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#FFF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M72.683 61.098L24.73 93.936l47.952 68.008" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#C9AF9D", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M202 238.112H51.333V20.395L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#EFEFEF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M202 238.112H58.91V20.395L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#3498DB", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M82.271 30.176V2.416l20.064.941v26.819" }),
                React.createElement("path", { fill: "#FFF4EE", fillRule: "nonzero", d: "M202 238.112H66.535V20.395L202 27.022z" }),
                React.createElement("path", { fill: "#FFE8D9", fillRule: "nonzero", d: "M202 238.112H66.535l84.104-57.11L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#C9AF9D", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M202 238.112h150.667V20.395L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#3498DB", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M336.172 56.73h19.465v19.737h-19.465" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#EFEFEF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M202 238.112h143.09V20.395L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#FF851B", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M328.842 107.86h19.465v19.737h-19.465" }),
                React.createElement("path", { fill: "#FFE8D9", fillRule: "nonzero", d: "M202 238.112h135.465V20.395L202 27.022z" }),
                React.createElement("path", { d: "M192.882 233.066H67.174l-.64 5.046H202V27.022l-3.194-.248-1.245 201.645a4.679 4.679 0 01-4.679 4.647zM205.194 26.75l-3.194.248v211.114h135.465l.639-5.046h-125.74a4.679 4.679 0 01-4.68-4.615l-2.49-201.7z", fill: "#E2C8B7", fillRule: "nonzero" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, strokeLinecap: "round", strokeLinejoin: "round", d: "M202 238.112H66.535V20.395L202 27.022zM202 238.112h135.465V20.395L202 27.022z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#FFF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M145.361 108.563l-63.09-.95V43.42l63.09 1.892z" }),
                React.createElement("path", { fillOpacity: 0.504, fill: "#FF851B", fillRule: "nonzero", d: "M135.709 93.122l-43.793-.95V54.184l43.793 1.892z" }),
                React.createElement("path", { d: "M123.437 55.549l-9.66-.415a4350.49 4350.49 0 01-21.885 20.112v11.832l31.545-31.53zM111.9 73.473l17.66-17.66-1.796-.08-16.766 16.766c.327.303.607.639.902.974zM135.709 62.44v-6.388l-1.82-.08-36.296 36.295 8.08.176z", fill: "#FD9", fillRule: "nonzero" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, strokeLinecap: "round", strokeLinejoin: "round", d: "M135.709 93.122l-43.793-.95V54.184l43.793 1.892z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#36BBF9", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M73.498 52.807l17.563-17.563 3.918 3.918-17.563 17.563zM131.667 116.326l17.563-17.563 3.918 3.918-17.563 17.563z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#FFF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M184.18 205.625h-63.09v-65.142l63.09.95z" }),
                React.createElement("path", { fill: "#21AA8F", fillRule: "nonzero", d: "M174.535 190.184h-43.8v-38.938l43.8.95z" }),
                React.createElement("path", { d: "M172.535 150.962v-.798l-22.355-.48c-7.16 7.101-14.318 14.204-21.477 21.31v17.19h6.57l37.262-37.222z", fill: "url(#prefix__a)", fillRule: "nonzero", transform: "translate(2 2)" }),
                React.createElement("path", { d: "M172.535 168.463v-12.99L139.8 188.208h12.775c6.68-6.58 13.333-13.16 19.96-19.745z", fill: "url(#prefix__b)", fillRule: "nonzero", transform: "translate(2 2)" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#36BBF9", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M167.652 140.957l3.918-3.918 17.563 17.563-3.918 3.918z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, strokeLinecap: "round", strokeLinejoin: "round", d: "M174.535 190.184h-43.8v-38.938l43.8.95z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#36BBF9", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M117.997 192.178l3.918-3.918 17.563 17.563-3.918 3.918z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#FFF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M283.078 205.625h-63.09v-63.25l63.09-1.892z" }),
                React.createElement("path", { fillOpacity: 0.504, fill: "#3498DB", fillRule: "nonzero", d: "M273.425 190.184h-43.792v-37.046l43.792-.942z" }),
                React.createElement("path", { fill: "url(#prefix__c)", fillRule: "nonzero", d: "M242.291 188.184h29.134v-29.142z", transform: "translate(2 2)" }),
                React.createElement("path", { fill: "url(#prefix__d)", fillRule: "nonzero", d: "M267.146 150.283l-18.779.408-20.758 20.758v16.767h1.597z", transform: "translate(2 2)" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, strokeLinecap: "round", strokeLinejoin: "round", d: "M273.425 190.184h-43.792v-37.046l43.792-.942z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#36BBF9", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M211.218 198.794l3.918-3.918L232.7 212.44l-3.918 3.919z" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, fill: "#FFF", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round", d: "M110.383 150.607l-28.112-.304M155.741 129.481l-73.47-.311M110.383 165.856H82.271M110.383 181.002H82.271M321.705 166.806h-28.112M321.705 152.355l-28.112.312M321.705 181.944h-28.112M321.705 196.802h-28.112M183.86 54.184l-28.119-.942M183.86 68.794l-28.119-.95M183.86 83.94l-28.119-.95M183.86 98.487l-13.054-.439M320.323 50.399l-99.505 4.735M320.323 65.952l-99.505 3.785M320.323 82.048l-99.505 2.834M320.323 97.074l-99.505 1.892M320.323 112.691l-99.505 1.892M255.158 128.53c-11.322-.606-22.819 1.31-34.332.656" }),
                React.createElement("path", { d: "M292.754 139.764a9.82 9.82 0 11-19.347 3.38 9.82 9.82 0 0119.347-3.38z", stroke: "#273B48", strokeWidth: 3.194, fill: "#3498DB", fillRule: "nonzero", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { stroke: "#273B48", strokeWidth: 3.194, strokeLinecap: "round", strokeLinejoin: "round", d: "M2.271 238.112h399.458" })))));
}
var MemoEmptyBookImage = React.memo(EmptyBookImage);
exports["default"] = MemoEmptyBookImage;
