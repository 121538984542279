"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function ArrowLeftIcon() {
    return (React.createElement("svg", { width: "24", height: "24" },
        React.createElement("rect", { width: "24", height: "24", fill: "none", rx: "0", ry: "0" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.97502 6.77027C10.005 6.84027 10.025 6.92027 10.025 7.00027H10.035V11.4003H19.775C19.995 11.4003 20.175 11.5803 20.175 11.8003V12.2003C20.175 12.4203 19.995 12.6003 19.775 12.6003H10.035V17.0103C10.035 17.0903 10.015 17.1703 9.98502 17.2403C9.92502 17.3803 9.81502 17.5003 9.66502 17.5603C9.59502 17.5903 9.51502 17.6103 9.43502 17.6103C9.35502 17.6103 9.27502 17.5903 9.20502 17.5603C9.12502 17.5303 9.06502 17.4903 9.00502 17.4303L4.00502 12.4303C3.77502 12.1903 3.77502 11.8103 4.00502 11.5803L9.00502 6.58027C9.05502 6.52027 9.12502 6.48027 9.19502 6.45027C9.34502 6.39027 9.50502 6.39027 9.65502 6.45027C9.80502 6.51027 9.91502 6.62027 9.97502 6.77027Z" })));
}
var MemoArrowLeftIcon = React.memo(ArrowLeftIcon);
exports["default"] = MemoArrowLeftIcon;
