"use strict";
// import styled, { createGlobalStyle } from 'styled-components';
exports.__esModule = true;
exports.defaultTheme = void 0;
var cta = '#FF851B';
var ctaHover = '#584235';
// const primaryColor = '#217dbb';
var primaryColor = '#3498DB';
var secondaryColor = '#34495a';
var lightPrimary = '#C8E2FC';
exports.defaultTheme = {
    fontFamily: {
        "default": 'Open Sans, serif',
        light: 'Open Sans Light, serif',
        regular: 'Open Sans Light, serif',
        // default: 'Montserrat,sans-serif',
        mono: 'Menlo, Monaco, monospace, serif'
    },
    colors: {
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        lightPrimary: lightPrimary,
        cta: cta,
        ctaHover: ctaHover,
        // text: '#4b5767',
        text: '#506472',
        lightest: '#EEE',
        // link: 'rgb(51, 71, 91)',
        link: '#3498DB',
        linkHover: cta,
        warningNoooo: '#fff3cd',
        warning: '#FF9000',
        muted: '#BEBEBE',
        success: '#39AD0D',
        failure: 'red',
        lightBackground: '#F7FBFF',
        transparent: 'transparent',
        lightGray: '#e9ecef',
        // lightGray: '#F7FBFF',
        borderColor: '#e3ecf3',
        borderRadius: '8px',
        danger: '#D44E48'
    },
    buttons: {
        addButton: {
            backgroundColor: cta,
            activeBackgroundColor: '#FF6F00',
            hoverBackgroundColor: '#FF9438'
        },
        primary: {
            backgroundColor: primaryColor,
            activeBackgroundColor: '#027FD4',
            hoverBackgroundColor: '#41AAF0',
            hoverBorderColor: '#41AAF0'
        },
        secondary: {
            backgroundColor: 'white',
            activeBackgroundColor: '#F4F9FD',
            hoverBorderColor: '#3498DB'
        }
    },
    forms: {
        input: {
            backgroundColor: '#fff',
            borderColor: '#dfeefd'
        }
    },
    blogs: {
        defaultPostPadding: '40px'
    }
};
exports["default"] = exports.defaultTheme;
