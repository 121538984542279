"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function PlusButton(props) {
    return (React.createElement("svg", __assign({ width: 32, height: 32 }, props),
        React.createElement("defs", null,
            React.createElement("filter", { x: "0%", y: "-.6%", width: "100.1%", height: "102.5%", filterUnits: "objectBoundingBox", id: "prefix__a" },
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.920585337 0 0 0 0 0.931783421 0 0 0 0 0.950973732 0 0 0 1 0", "in": "shadowOffsetOuter1" })),
            React.createElement("path", { id: "prefix__b", d: "M0 0h1440v80H0z" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "#FFF", d: "M-351-24h1440v1024H-351z" }),
            React.createElement("g", { transform: "translate(-351 -24)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__a)", xlinkHref: "#prefix__b" }),
                React.createElement("use", { fill: "#FFF", xlinkHref: "#prefix__b" })),
            React.createElement("path", { d: "M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16A16 16 0 0016 0zm6.4 16.8a.8.8 0 01-.8.8h-4v4a.8.8 0 01-.8.8h-1.6a.8.8 0 01-.8-.8v-4h-4a.8.8 0 01-.8-.8v-1.6a.8.8 0 01.8-.8h4v-4a.8.8 0 01.8-.8h1.6a.8.8 0 01.8.8v4h4a.8.8 0 01.8.8v1.6z", fill: "#FF851B" }))));
}
var MemoPlusButton = React.memo(PlusButton);
exports["default"] = MemoPlusButton;
