"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function EditorUndoIcon(props) {
    return (React.createElement("svg", __assign({ width: "21px", height: "8px", viewBox: "0 0 21 8" }, props),
        React.createElement("defs", null,
            React.createElement("filter", { x: "-3.3%", y: "-5.2%", width: "106.7%", height: "114.5%", filterUnits: "objectBoundingBox", id: "prefix__b" },
                React.createElement("feOffset", { dy: 8, "in": "SourceAlpha", result: "shadowOffsetOuter1" }),
                React.createElement("feGaussianBlur", { stdDeviation: 8, "in": "shadowOffsetOuter1", result: "shadowBlurOuter1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.313824686 0 0 0 0 0.393559942 0 0 0 0 0.446982563 0 0 0 0.1 0", "in": "shadowBlurOuter1" })),
            React.createElement("filter", { x: "-.1%", y: "-.9%", width: "100.1%", height: "101.8%", filterUnits: "objectBoundingBox", id: "prefix__e" },
                React.createElement("feOffset", { dy: -1, "in": "SourceAlpha", result: "shadowOffsetInner1" }),
                React.createElement("feComposite", { "in": "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: -1, k3: 1, result: "shadowInnerInner1" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 0.964705882 0 0 0 0 0.97254902 0 0 0 0 0.980392157 0 0 0 1 0", "in": "shadowInnerInner1", result: "shadowMatrixInner1" }),
                React.createElement("feOffset", { dy: 1, "in": "SourceAlpha", result: "shadowOffsetInner2" }),
                React.createElement("feComposite", { "in": "shadowOffsetInner2", in2: "SourceAlpha", operator: "arithmetic", k2: -1, k3: 1, result: "shadowInnerInner2" }),
                React.createElement("feColorMatrix", { values: "0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0", "in": "shadowInnerInner2", result: "shadowMatrixInner2" }),
                React.createElement("feMerge", null,
                    React.createElement("feMergeNode", { "in": "shadowMatrixInner1" }),
                    React.createElement("feMergeNode", { "in": "shadowMatrixInner2" }))),
            React.createElement("linearGradient", { x1: "50%", y1: "0%", x2: "50%", y2: "100%", id: "prefix__a" },
                React.createElement("stop", { stopColor: "#FAFCFE", offset: "0%" }),
                React.createElement("stop", { stopColor: "#EFF7FF", offset: "100%" })),
            React.createElement("rect", { id: "prefix__c", x: 120, y: 529, width: 840, height: 385, rx: 8 }),
            React.createElement("path", { d: "M128 529h824a8 8 0 018 8v48H120v-48a8 8 0 018-8z", id: "prefix__d" })),
        React.createElement("g", { fill: "none", fillRule: "evenodd" },
            React.createElement("path", { fill: "transparent", d: "M-623-553H817V577H-623z" }),
            React.createElement("path", { d: "M0 0h1440v1050H0z", transform: "translate(-623 -473)" }),
            React.createElement("g", { transform: "translate(-623 -553)" },
                React.createElement("use", { fill: "#000", filter: "url(#prefix__b)", xlinkHref: "#prefix__c" }),
                React.createElement("use", { fill: "transparent", xlinkHref: "#prefix__c" })),
            React.createElement("g", { transform: "translate(-623 -553)" },
                React.createElement("use", { fillOpacity: 0.101, fill: "#EFF2F6", xlinkHref: "#prefix__d" }),
                React.createElement("use", { fill: "#000", filter: "url(#prefix__e)", xlinkHref: "#prefix__d" })),
            React.createElement("path", { d: "M-2-8h24v24H-2z" }),
            React.createElement("path", { d: "M21 7.46a.5.5 0 01-.5.54H19a.49.49 0 01-.49-.45c-.29-2.82-3.31-5.05-7-5.05a7.9 7.9 0 00-5.19 1.83l2.52 2.52a.49.49 0 01.15.36v.29a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-7A.5.5 0 011.5 0h.29a.49.49 0 01.36.15l2.42 2.42a10.37 10.37 0 017-2.57c4.98 0 9.1 3.29 9.43 7.46z", fill: "#8A9AA5", fillRule: "nonzero" }))));
}
var MemoEditorUndoIcon = React.memo(EditorUndoIcon);
exports["default"] = MemoEditorUndoIcon;
